import { apiInstance } from 'configuration/axios'
import Swal from 'sweetalert2'
import { useRecoilState } from 'recoil'
import { authModalAtom } from 'recoil/atom/AuthModalAtom'
import { authAtom } from 'recoil/atom/AuthAtom'
import { yourPageAtom } from 'recoil/atom/YourPageAtom'
import useMockApi from 'hooks/useMockApi'

export const useAuth = (): {
  isLogin: boolean
  login: () => void
  logout: () => void
  register: () => void
  forgotPassword: () => void
  renewPassword: () => void
  editProfile: () => void
} => {
  const [, setAuthModalValue] = useRecoilState(authModalAtom)
  const [, setYourPageValue] = useRecoilState(yourPageAtom)
  const [authValue, setAuthValue] = useRecoilState(authAtom)
  // Register Custom Sweet Alert Button
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'swal theme-btn btn-style-one-other text-center text-white',
      cancelButton: 'swal theme-btn btn-style-show_all  text-center text-white',
    },
    buttonsStyling: true,
  })

  const isLogin = authValue.isAuth

  const login = () => {
    const mock = useMockApi()
    mock.onPost('/auth/login').reply(200, {
      data: {
        email: 'test@test.com',
      },
    })

    // mock.onPost("/auth/login").reply(401,{"message":"login failed"});
    apiInstance
      .post(`/auth/login`)
      .then(({ data }) => {
        const { email } = data.data

        setAuthValue(prevState => ({
          ...prevState,
          email,
          isAuth: true,
        }))

        setAuthModalValue(prevState => ({
          ...prevState,
          authType: 'profile',
        }))
      })
      .catch(err => {
        console.log(err)
      })

    mock.restore()
  }

  const logout = () => {
    setAuthModalValue(prevState => ({
      ...prevState,
      isOpenAuthModal: false,
      isAuth: false,
    }))

    setAuthValue(prevState => ({
      ...prevState,
      email: null,
      isAuth: false,
    }))

    setTimeout(() => {
      setAuthModalValue(prevState => ({
        ...prevState,
        authType: 'login',
      }))
    }, 1500)
  }

  const register = () => {
    swalWithBootstrapButtons
      .fire({
        title: 'กรุณายืนยันบัญชีของท่านผ่านอีเมล',
        text: 'ระบบได้ทำการส่งอีเมลสำหรับเปิดใช้บัญชีเข้าสู่อีเมลที่ท่านระบุไว้เรียบร้อยแล้ว คลิ๊กปุ่มยืนยันเพื่อเปิดใช้งานบัญชีของท่าน',
        html:
          '<div>ระบบได้ทำการส่งอีเมลสำหรับเปิดใช้บัญชีเข้าสู่อีเมลที่ท่านระบึไว้เรียบร้อยแล้ว คลิกปุ่มยืนยันเพื่อเปิดใช้งานบัญชีของท่าน</div> <br/>' +
          '<div class="d-flex justify-content-center align-items-center" style="column-gap:12px">' +
          '<a id="register_send_email_button" class="theme-btn btn-style-one-other w-50" style="border-radius:6px"><span class="txt">ส่งอีเมลอีกครั้ง</span></a>' +
          ' <a id="register_close_button" class="theme-btn btn-style-show_all w-50 text-center"><span class="txt">ปิด</span></a>' +
          '</div>',
        icon: 'success',
        showCancelButton: false,
        showConfirmButton: false,
        confirmButtonText: 'ส่งอีเมลอีกครั้ง',
        cancelButtonText: 'ปิด',
      })
      .then(() => {
        return
      })

    const registerSendEmailButton = document.querySelector('#register_send_email_button')
    const registerCloseEmailButton = document.querySelector('#register_close_button')

    if (registerSendEmailButton) {
      registerSendEmailButton.addEventListener('click', () => {
        setAuthModalValue(prevState => ({
          ...prevState,
          isOpenAuthModal: false,
        }))

        setYourPageValue(prevState => ({
          ...prevState,
          isOpen: true,
        }))

        swalWithBootstrapButtons.close()

        setTimeout(() => {
          setAuthModalValue(prevState => ({
            ...prevState,
            authType: 'login',
          }))
        }, 1500)
      })
    }

    if (registerCloseEmailButton)
      registerCloseEmailButton.addEventListener('click', () => {
        setAuthModalValue(prevState => ({
          ...prevState,
          isOpenAuthModal: false,
        }))

        setYourPageValue(prevState => ({
          ...prevState,
          isOpen: true,
        }))

        swalWithBootstrapButtons.close()

        setTimeout(() => {
          setAuthModalValue(prevState => ({
            ...prevState,
            authType: 'login',
          }))
        }, 1500)
      })
  }

  const forgotPassword = () => {
    setAuthModalValue(prevState => ({
      ...prevState,
      isOpenAuthModal: false,
    }))

    setTimeout(() => {
      setAuthModalValue(prevState => ({
        ...prevState,
        authType: 'login',
      }))
    }, 1500)
  }

  const renewPassword = () => {
    setAuthModalValue(prevState => ({
      ...prevState,
      isOpenAuthModal: false,
    }))

    setTimeout(() => {
      setAuthModalValue(prevState => ({
        ...prevState,
        authType: 'login',
      }))
    }, 1500)
  }

  const editProfile = () => {
    // setAuthModalValue(prevState => ({
    //   ...prevState,
    //   isOpenAuthModal: false,
    // }))

    // setTimeout(() => {
    //   setAuthModalValue(prevState => ({
    //     ...prevState,
    //     authType: 'login',
    //   }))
    // }, 1500)
  }

  return {
    isLogin,
    login,
    logout,
    register,
    forgotPassword,
    renewPassword,
    editProfile,
  }
}
