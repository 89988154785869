import React, { useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
interface elementProps {
  className?: string
}
const SkeletonContentDetail = (props: elementProps) => {
  const className = props.className
    ? props.className
    : 'news-block style-two col-lg-4 col-md-6 col-sm-12'
  return (
    <div>
      <div
        className="inner-box wow fadeInLeft"
        data-wow-delay="0ms"
        data-wow-duration="1500ms"
        style={{ marginTop: '50px' }}>
        <div className="image">
          <Skeleton count={8} />
        </div>
        <div className="lower-content hl pt-2">
          <ul className="post-meta">
            <li>
              <Skeleton />
            </li>
          </ul>
          <h5>
            <Skeleton />
          </h5>
          <p className="text-l-cb">
            <Skeleton count={4} />
          </p>
          <Skeleton count={1} />

          <div style={{ marginTop: '50px' }}>
            <Skeleton count={8} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SkeletonContentDetail
