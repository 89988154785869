import { atom } from 'recoil'

interface AuthModalAtomContent {
  isOpenAuthModal: boolean
  authType: 'login' | 'register' | 'reset-password' | 'profile'
}

export const authModalAtom = atom<AuthModalAtomContent>({
  key: 'authModal',
  default: {
    isOpenAuthModal: false,
    authType: 'login',
  },
})
