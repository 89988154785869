import { atom } from 'recoil'

interface AuthAtomContent {
  email: string | null
  isAuth: boolean
}

export const authAtom = atom<AuthAtomContent>({
  key: 'auth',
  default: {
    email: null,
    isAuth: false,
  },
})
