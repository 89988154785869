import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { mediaContent } from 'interface'
interface elementProps {
  className?: string
  theme?: string
  content: mediaContent
}
const LastViewContentBox = (props: elementProps) => {
  const regex = /(<([^>]+)>)/gi
  const { page } = useParams()
  const className = props.className
    ? props.className
    : 'news-block style-two col-lg-4 col-md-6 col-sm-12'
  const linkToDetail = `/media/${page}/${props.content._table_name}/${props.content.id}`
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={className}>
      <div className="inner-box wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
        <div className="image">
          <Link to={linkToDetail}>
            <img src={`${props.content.cover}`} alt="" />
          </Link>

          <div className="overlay-box">
            <div className="overlay-inner">
              <div className="content">
                <Link to={linkToDetail} className="icon flaticon-unlink"></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="lower-content hl pt-2">
          <ul className="post-meta">
            <li>
              <Link to={linkToDetail}>
                <span className="icon fa fa-calendar"></span>
                {props.content.publishDate}
              </Link>
            </li>
          </ul>
          <h5>{props.content.title.replace(regex, '')}</h5>
          <p
            dangerouslySetInnerHTML={{ __html: props.content.shortDescription.replace(regex, '') }}
            className="text-l-cb"
            style={{ color: '#000' }}></p>
          <Link to={linkToDetail} className="theme-btn btn-style-show_all text-center">
            <span className="txt">อ่านต่อ</span>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default LastViewContentBox
