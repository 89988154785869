export const validatePageUrl = (
  url: string | undefined
):
  | {
      key: string
      type: string
    }
  | undefined => {
  const pages = [
    { key: 'illegalProduct', type: 'blog' },
    { key: 'checkSureShare', type: 'blog' },
    { key: 'vdocheckSureShare', type: 'video' },
    { key: 'newsUpdate', type: 'blog' },
    { key: 'infoGraphic', type: 'blog' },
    { key: 'animation', type: 'video' },
    { key: 'video', type: 'video' },
    { key: 'e-book', type: 'blog' },
    { key: 'podcast', type: 'blog' },
    { key: 'factSheet', type: 'blog' },
    { key: 'misc', type: 'blog' },
    { key: 'men', type: 'blog' },
    { key: 'women', type: 'blog' },
    { key: 'elder', type: 'blog' },
    { key: 'kids', type: 'blog' },
    { key: 'entrepreneur', type: 'blog' },
    { key: 'ncds', type: 'blog' },
  ]
  const result = pages.find(page => page.key === url)
  return result
}

export const getSearchCategory = () => {
  return [
    { key: 'checkSureShare', type: 'blog' },
    { key: 'newsUpdate', type: 'blog' },
    { key: 'infoGraphic', type: 'blog' },
    { key: 'animation', type: 'video' },
    { key: 'video', type: 'video' },
    { key: 'e-book', type: 'blog' },
    { key: 'factSheet', type: 'blog' },
  ]
}

export const thaimonth = [
  'มกราคม',
  'กุมภาพันธ์',
  'มีนาคม',
  'เมษายน',
  'พฤษภาคม',
  'มิถุนายน',
  'กรกฎาคม',
  'สิงหาคม',
  'กันยายน',
  'ตุลาคม',
  'พฤศจิกายน',
  'ธันวาคม',
]

export const getPageFromTableName = (table: string) => {
  const x: any = {
    media_printing: 'infoGraphic',
    media_specify: 'checkSureShare',
    media_news: 'newsUpdate',
    media_animation: 'animation',
    media_tv: 'video',
    media_magazine: 'e-book',
    media_podcast: 'podcast',
  }
  return x[table] ?? ''
}
