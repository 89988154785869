import { useRoutes } from 'react-router-dom'

import routes from './Routes'
import ReactGA from "react-ga4";
import React from 'react'

function App() {
  console.log('GA_ID', process.env.REACT_APP_GA_ID)
  ReactGA.initialize(process.env.REACT_APP_GA_ID ?? '')
  // ReactGA.pageview(window.location.pathname + window.location.search)
  const element = useRoutes(routes)

  return (
    <div>
      <React.Suspense fallback={<>...</>}>{element}</React.Suspense>
    </div>
  )
}
export default App
