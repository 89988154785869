import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { mediaContent } from 'interface'
import ModalVideo from 'react-modal-video'
import 'react-modal-video/css/modal-video.min.css'
interface elementProps {
  className?: string
  theme?: string
  content: mediaContent
  page?: string
  linkDetail: string
}
const VideoContentBox = (props: elementProps) => {
  const [isOpenModalVideo, setOpenModalVideo] = useState(false)
  const regex = /(<([^>]+)>)/gi
  let page
  if (props.page) {
    page = props.page
  } else {
    const param = useParams()
    page = param.page
  }
  const getYoutubeThumbnail = (url: string) => {
    const youtubeKey = getYoutubeKey(url)
    if (youtubeKey && youtubeKey.length === 11) {
      return `https://img.youtube.com/vi/${youtubeKey}/0.jpg`
    }
    return ''
  }
  const getYoutubeKey = (url: string) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
    const match = url.match(regExp)
    return match && match[7] ? match[7] : ''
  }
  const handleOpenYoutube = (e: any) => {
    e.preventDefault()
    setOpenModalVideo(true)
  }
  const getFileExtension = (filename: string) => {
    return filename.split('.').pop() || null
  }

  const className = props.className
    ? props.className
    : 'news-block style-two col-lg-4 col-md-6 col-sm-12'
 
  const picCover = props.content.youtubeLink
    ? getYoutubeThumbnail(props.content.youtubeLink)
    : `${process.env.REACT_APP_CONTENT_MEDIA_URL}/${props.content.filePath}/${props.content.cover}`
  const fileUrl = process.env.REACT_APP_CONTENT_MEDIA_URL + '/' + props.content.videoUrlOrFileUrl
  const fileExt = props.content.videoUrlOrFileUrl?getFileExtension(props.content.videoUrlOrFileUrl):null

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={className}>
      {props.content.youtubeLink && (
        <ModalVideo
          channel="youtube"
          isOpen={isOpenModalVideo}
          videoId={getYoutubeKey(props.content.youtubeLink)}
          onClose={() => setOpenModalVideo(false)}
        />
      )}
      {!props.content.youtubeLink && fileExt === 'mp4' && (
        <ModalVideo
          channel="custom"
          isOpen={isOpenModalVideo}
          url={fileUrl ?? ''}
          onClose={() => setOpenModalVideo(false)}
        />
      )}
      <div className="inner-box wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
        <div className="image">
          <img src={picCover} alt="" />

          <div className="overlay-box">
            <div className="overlay-inner">
              <div className="content">
                {props.content.youtubeLink && (
                  <a href="#" onClick={handleOpenYoutube} className="icon flaticon-unlink"></a>
                )}
                {!props.content.youtubeLink && fileExt === 'mp4' && (
                  <a href="#" onClick={handleOpenYoutube} className="icon flaticon-unlink"></a>
                )}
                {!props.content.youtubeLink && fileExt !== 'mp4' && (
                  <Link to={props.linkDetail} className="icon flaticon-unlink"></Link>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="lower-content hl pt-2">
          <ul className="post-meta">
            <li>
              <Link to={props.linkDetail}>
                <span className="icon fa fa-calendar"></span>
                {props.content.publishDate}
              </Link>
            </li>
          </ul>
          <Link to={props.linkDetail}>
            <h5>{props.content.title.replace(regex, '')}</h5>
            <p
              dangerouslySetInnerHTML={{
                __html: props.content.shortDescription.replace(regex, ''),
              }}
              className="text-l-cb"
              style={{ color: '#000' }}></p>
          </Link>
          <Link to={props.linkDetail} className="theme-btn btn-style-show_all text-center">
            <span className="txt">อ่านต่อ</span>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default VideoContentBox
