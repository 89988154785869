import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { apiInstance } from 'configuration/axios'

const TagSearch = (): JSX.Element => {
  const [content, setContent] = useState<string[]>([])
  useEffect(() => {
    apiInstance
      .get(`popularKeyword`)
      .then(res => {
        if (res.data) {
          setContent(res.data)
        }
      })
      .catch(err => {
        console.error(err)
      })
  }, [])
  return (
    <div className="sidebar-widget tags_search">
      <h5>คำค้นหายอดฮิต</h5>
      <div className="widget-content">
        {content.map((tag, index) => (
        <Link key={`tag${index}`} to={`/search?keyword=${tag}&category=all`}>{tag}</Link>))}
       
      </div>
    </div>
  )
}
export default TagSearch
