import React from 'react'
import { Navigate } from 'react-router-dom'

import { useAuth } from 'hooks/useAuth'

const AuthGuard = ({ children }: { children: React.ReactNode }) => {
  const { isLogin } = useAuth()

  console.log('isLogin', isLogin)

  if (!isLogin) {
    return <Navigate replace to="/" />
  }

  return <>{children}</>
}

export default AuthGuard
