import React, { useState, useEffect } from 'react'
import { apiInstance } from 'configuration/axios'
import { mediaContent } from 'interface'
import SkeletonContentBox from 'components/ContentBox/SkeletonContentBox'
import SidebarSuggestContentBox from 'components/ContentBox/sidebarSuggestContentBox'

const SuggestContent = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true)
  const [content, setContent] = React.useState<mediaContent[]>([])
  useEffect(() => {
    apiInstance
      .get(`popularContent`)
      .then(res => {
        if (res.data) {
          setContent(res.data)
        }

        setLoading(false)
      })
      .catch(err => {
        console.error(err)
      })
  }, [])

  return (
    <div className="recommended sidebar-widget">
      <h5>บทความแนะนำ</h5>

      {loading &&
        Array.from({ length: 1 }, (_, i) => (
          <SkeletonContentBox key={`skeleton-${i}`} className="news-block col-sm-12" />
        ))}

      {content.length > 0 &&
        content.map((content, index) => (
          <SidebarSuggestContentBox key={`sidebarSuggestSuggest-${index}`} content={content} />
        ))}
    </div>
  )
}
export default SuggestContent
