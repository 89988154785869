import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useParams, Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { validatePageUrl } from 'utils'
import { BlogContentBox, SkeletonContentBox, VideoContentBox } from 'components/ContentBox'
import TagSearch from 'components/Media/tagSearch'
import SuggestContent from 'components/Media/suggestContent'
import { apiInstance } from 'configuration/axios'
import { mediaContent, podcastContent } from 'interface'
import SearchInPageForm from '../../components/SearchForm/searchInPageForm'
import PodcastContentBox from '../../components/ContentBox/podcastContentBox'
import { usePrevious } from 'hooks/usePrevious'
// import Skeleton from 'react-loading-skeleton'
// import 'react-loading-skeleton/dist/skeleton.css'
const SuggestVideo = () => {
  const { page } = useParams()
  const { t } = useTranslation()

  const [content, setContent] = React.useState<mediaContent[]>([])
  const [inPageSearch, setInPageSearch] = React.useState<string>('')
  const [sortBy, setSortBy] = React.useState<string>('last')
  const [podcastContent, setPodcastContent] = React.useState<podcastContent[]>([])

  const [loading, setLoading] = React.useState<boolean>(true)
  const [currentPage, setCurrentPage] = React.useState(1)
  const [pageData, setPageData] = React.useState({
    last: 0,
    offset: 0,
    page: 1,
    per_page: 8,
    total_rows: 0,
  })
  const prevData = usePrevious({ page, currentPage })

  const fetchData = () => {
    apiInstance
      .get(`video/lists?page=${currentPage}&sort=${sortBy}&limit=${pageData.per_page}`)
      .then(res => {
        if (res.data?.data) {
          setContent(res.data.data)
          setPageData(res.data.pagination)
        }

        setLoading(false)
      })
      .catch(err => {
        console.error(err)
      })
  }

  const clearContent = () => {
    if (content.length > 0) setContent([])
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    setLoading(true)
    clearContent
    if (prevData && prevData?.page !== page && prevData?.currentPage !== 1) {
      return
    }
    fetchData()
  }, [currentPage, page, sortBy])

  useEffect(() => {
    setCurrentPage(1)
  }, [page, sortBy])

  const handleSortContent = (event: any) => {
    setSortBy(event.target.value)
  }

  const handleKeySearchInpage = (event: any) => {
    setInPageSearch(event.target.value)
  }
  const onChangePage = (event: any, page: number) => {
    event.preventDefault()
    // window.scrollTo(0, 0);
    setCurrentPage(page)
  }

  const renderLinkToDetail = (content : mediaContent) => {
    return `/vdo-recommend/${content._table_name}/${content.id}`
  }
  // useEffect(() => {
  //     const script = document.createElement("script");
  //     script.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v14.0&appId=367801984327528&autoLogAppEvents=1";
  //     script.async = true;
  //     script.onload = () => this.scriptLoaded();
  //     document.body.appendChild(script);
  // }, [])

  return (
    <div>
      <section className="update-breadcrumb">
        <div className="auto-container">
          <div className="sec-title">
            <div className="clearfix">
              <ul className="cbreadcrumb">
                <li>
                  <Link to="/">หน้าหลัก</Link>
                </li>
                <li>วิดีโอแนะนำ</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="search-keyword-type2">
        <div className="auto-container">
          <div className="newsletter-form">
            <SearchInPageForm />
            <div className="display_result">
              <div className="row">
                <div className="col-12">
                  <h1 style={{display:'inline-block'}} className="fs-36">วิดีโอแนะนำ</h1> <span className="fs-36 mx-5">ค้นหาพบ {pageData.total_rows ?? ''} รายการ</span>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-3 col-md-5 col-sm-5">
                  {/* <div className="form-group mt-3">
                                    <input type="text" name="keyword" placeholder="ตัวกรองคำค้นหา" onChange={handleKeySearchInpage} />
                                </div> */}
                </div>
                <div className="col-12 col-lg-7 col-md-3 col-sm-3"></div>
                <div className="col-12 col-lg-2 colmd-4 col-sm-4">
                  <select className="legSelect" name="option" id="" onChange={handleSortContent}>
                    <option value="last">ล่าสุด</option>
                    <option value="popular">ยอดนิยม</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-8">
                <div className="display_result">
                  <div className="row clearfix mt-3">
                    {loading &&
                      Array.from({ length: 4 }, (_, i) => (
                        <SkeletonContentBox
                          key={`skeleton-${i}`}
                          className="news-block style-two col-lg-6 col-md-6 col-sm-12"
                        />
                      ))}

                    {content.length > 0 &&
                      content
                        .filter(
                          x =>
                            x.title.includes(inPageSearch) ||
                            x.shortDescription.includes(inPageSearch)
                        )
                        .map((content, index) => (
                          <VideoContentBox
                            key={`content-${index}`}
                            content={content}
                            className={`news-block style-two col-lg-6 col-md-6 col-sm-12`}
                            linkDetail={renderLinkToDetail(content)}
                          />
                        ))}

             
                  </div>
                </div>
              </div>
              <div className="sidebar-side col-12 col-lg-4 col-md-4 mt-3 spa">
                <aside className="sidebar sticky-top ">
                  {/* <div className="fb-page" data-href="https://www.facebook.com/FDAThai" data-tabs="timeline" data-width="" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/FDAThai" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/FDAThai">FDA Thai</a></blockquote></div> */}
                  {process.env.NODE_ENV !== 'development' && (
                    <iframe
                      src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FFDAThai&tabs=timeline&width=340&height=331&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=367801984327528"
                      width="340"
                      height="331"
                      style={{ border: 'none', overflow: 'hidden' }}
                      scrolling="no"
                      frameBorder="0"
                      allowFullScreen={true}
                      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                  )}
                  <SuggestContent />
                  <TagSearch />
                </aside>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mb-5">
        <div className="auto-container">
          <div className="styled-pagination text-center">
            <ul className="clearfix">
          
            {currentPage - 1 > 0 && (
              <li>
                  <a href="#" onClick={e => onChangePage(e, 1)}>
                    <span className="fa fa-angle-double-left"></span>{' '}
                  </a>
                </li>
                )}
              {currentPage - 1 > 0 && (
                <li className="prev">
                  <a href="#" onClick={e => onChangePage(e, currentPage - 1)}>
                    <span className="fa fa-angle-left"></span>{' '}
                  </a>
                </li>
              )}
              {currentPage - 1 > 0 && (
                <li>
                  <a href="#" onClick={e => onChangePage(e, currentPage - 1)}>
                    {currentPage - 1}
                  </a>
                </li>
              )}
              <li className="active">
                <a href="#" onClick={e => e.preventDefault()}>
                  {currentPage}
                </a>
              </li>
              {currentPage + 1 <= pageData.last && (
                <li>
                  <a href="#" onClick={e => onChangePage(e, currentPage + 1)}>
                    {currentPage + 1}
                  </a>
                </li>
              )}
              {currentPage == 1 && currentPage + 2 <= pageData.last && (
                <li>
                  <a href="#" onClick={e => onChangePage(e, currentPage + 2)}>
                    {currentPage + 2}
                  </a>
                </li>
              )}
              {currentPage + 1 <= pageData.last && (
                <li className="next">
                  <a href="#" onClick={e => onChangePage(e, currentPage + 1)}>
                    <span className="fa fa-angle-right"></span>{' '}
                  </a>
                </li>
              )}
              {currentPage + 1 <= pageData.last && (
              <li>
                  <a href="#" onClick={e => onChangePage(e, pageData.last)}>
                    <span className="fa fa-angle-double-right"></span>{' '}
                  </a>
                </li>
                )}
            </ul>

            
          </div>
        </div>
      </section>
    </div>
  )
}

export default SuggestVideo
