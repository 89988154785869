import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getSearchCategory } from '../../utils'
import { useRecoilState } from 'recoil'
import { searchAtom } from '../../recoil/atom/SearchAtom'
import { DebounceInput } from 'react-debounce-input'
import { useNavigate } from 'react-router-dom'
import { apiInstance } from '../../configuration/axios'
const SearchInPageForm = () => {
  const seatchCategory = getSearchCategory()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchValue, setSearch] = useRecoilState(searchAtom)
  const [searchSuggestions, setSearchSuggestions] = useState<string[]>([])
  const [isOpenSuggestion, setIsOpenSuggestion] = useState(false)
  const [suggestWord, setSuggestWord] = useState<string[]>([])
  const handleChange = (e: any) => {
    // console.log('searchState',_)
    setSearch(prev => {
      return { ...prev, category: e.target.value }
    })
  }
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)
  const handleSearch = (event: any) => {
    event.preventDefault()
    if (searchValue.category !== '')
      navigate(`/search?keyword=${searchValue.keyword}&category=${searchValue.category}`)
    else console.log('searchValue', searchValue)
    return
  }

  const handleInputKeyword = (e: any) => {
    setSearch(prev => {
      return { ...prev, keyword: e.target.value }
    })
    setIsOpenSuggestion(true)
  }
  const handleSelectSuggestion = (event: any) => {
    const val = event.target.innerText
    setSearch(prev => {
      return { ...prev, keyword: val }
    })
    setIsOpenSuggestion(false)
  }
  const getSuggestion = async() =>  apiInstance.get(`autocomplete`).then(res => {
    
    setSearchSuggestions(res.data)
 })
  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOpenSuggestion(false)
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  useEffect(() => {
    if (searchValue?.keyword && searchValue.keyword?.length > 0) {
      const suggest = searchSuggestions
        .filter(e => e.includes(searchValue.keyword ?? ''))
        .slice(0, 6)
      setSuggestWord(suggest)
    } else {
      setIsOpenSuggestion(false)
    }
  }, [searchValue.keyword])

  useEffect(() => {
    getSuggestion();
   
  }, [])

  return (
    <form onSubmit={handleSearch} action="" ref={wrapperRef}>
      <div className="row">
        <div className="col-12 col-lg-7 col-md-5 col-sm-5">
          <div className="form-group">
            <input
              type="text"
              placeholder="กรุณาพิมพ์คำที่ต้องการ เช่น ยาสมุนไพร, ผิวขาว..."
              value={searchValue.keyword ?? ''}
              minLength={3}
              onChange={handleInputKeyword}
            />
            {isOpenSuggestion && suggestWord.length > 0 && (
              <div className="suggestionSearch  suggestionSearch-innerPage">
                <ul>
                  {suggestWord.map((item, index) => (
                    <li onClick={handleSelectSuggestion} key={`suggestion${index}`}>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="col-12 col-lg-3 col-md-3 col-sm-3">
          <div className="form-group">
            <select className="legSelect" name="option" id="" onChange={handleChange}>
              <option value="all">ตัวช่วยการค้นหา (ทั้งหมด)</option>
              {seatchCategory.map(e => (
                <option key={e.key} value={e.key}>
                  {t(`menu.${e.key}`)}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-12 col-lg-2 col-md-4 col-sm-4">
          <a
            href="#"
            onClick={handleSearch}
            className="theme-btn btn-style-three h-45 w-100 text-center">
            <span className="txt">
              <span className="icon fa fa-search mr-2"></span> ค้นหา
            </span>
          </a>
        </div>
      </div>
    </form>
  )
}

export default SearchInPageForm
