import { atom } from 'recoil'
interface searchAtomContent {
  keyword: string | null
  category: string | null
}

export const searchAtom = atom<searchAtomContent>({
  key: 'searchState',
  default: {
    keyword: '',
    category: 'all',
  },
})
