import React, { useState, useEffect } from 'react'
import { Link, useNavigate , useLocation, useParams } from 'react-router-dom'
import { BlogContentBox } from 'components/ContentBox'
import TagSearch from 'components/Media/tagSearch'
import SuggestContent from 'components/Media/suggestContent'
import SearchInPageForm from '../../components/SearchForm/searchInPageForm'
import { mediaContent, mediaContentDetail } from '../../interface'
import { apiInstance } from '../../configuration/axios'
import SkeletonContentDetail from '../../components/ContentBox/SkeletonContentDetail'
import ModalVideo from 'react-modal-video'
import 'react-modal-video/css/modal-video.min.css'
import LastViewContentBox from '../../components/ContentBox/lastViewContentBox'
import { FacebookIcon, FacebookShareButton, LineShareButton, LineIcon } from 'react-share'
import { useTranslation } from 'react-i18next'
interface FileAttach {
  fileUrl: string | null
  fileType: string | null
}
interface LastView {
  id: number
  cover: string | null
  title: string | null
  publishDate: string | null
  _table_name: string | null
  createdTime: number
}
const SuggestVideoDetail = () => {
  const { t } = useTranslation()
  const navigate = useNavigate();
  const { search }: { search: string; state: any } = useLocation()
  const sp = new URLSearchParams(search)
  const { table, id } = useParams()
  const [content, setContent] = useState<mediaContentDetail | null>(null)
  const [fileAttach, setFileAttach] = useState<FileAttach>({} as FileAttach)
  const [loading, setLoading] = useState<boolean>(true)
  const getFileExtension = (filename: string) => {
    return filename.split('.').pop() || null
  }
  const [isOpenModalVideo, setOpenModalVideo] = useState(false)
  const [lastView, setLastView] = useState<LastView[]>([])
  const [showWordingCheck, setShowWordingCheck] = useState(false)
  

  const getYoutubeKey = (url: string) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
    const match = url.match(regExp)
    return match && match[7] ? match[7] : ''
  }
  const renderMainMenuBC = () => {
    const ref = sp.get('ref')
    if (ref === 'search') {
      return <Link to={`/search`}>ผลลัพธ์การค้นหา</Link>
    } else {
      return <Link to={`/vdo-recommend`}>วิดีโอแนะนำ</Link>
    }
  }
  const renderTitleBC = (title: string) => {
    if (title && title.length > 20) {
      return title.substring(0, 20) + ' ...'
    } else {
      return title
    }
  }

  useEffect(() => {
    setLoading(true)

    window.scrollTo(0, 0)
    const saved = localStorage.getItem('lastView_02')
    const lastView = saved ? JSON.parse(saved) : []

    if (lastView) setLastView(lastView)
    if (content) setContent(null)
    
    apiInstance
      .get(`media/animation/${table}/${id}`)
      .then(res => {
        console.log(res.data)
        if (res.data?.data && id) {
          
          if(typeof(res.data?.data.is_show) === 'undefined' || parseInt(res.data.data.is_show) === 0){
            return navigate("/");
          }
          
          const relateContent =
            res.data.relate?.filter((e: any) => {return console.log(parseInt(e.id),content?.id); parseInt(e.id) !== content?.id})
          const fetchContent: mediaContentDetail = { ...res.data.data, relate: relateContent }
          console.log('fetchContent',fetchContent)
          setContent(fetchContent)
          const fileExt = fetchContent.videoUrlOrFileUrl
            ? getFileExtension(fetchContent.videoUrlOrFileUrl)
            : null
          const fileUrl = process.env.REACT_APP_MEDIA_URL + '/' + fetchContent.videoUrlOrFileUrl
          setFileAttach((prevState: FileAttach) => {
            return { ...prevState, fileType: fileExt, fileUrl }
          })
          if (lastView.findIndex((e: any) => parseInt(e.id) === parseInt(id)) === -1) {
            let updateLastView = [
              ...lastView,
              {
                id: id,
                cover: `${process.env.REACT_APP_MEDIA_URL}/${fetchContent.filePath}/${fetchContent.cover}`,
                title: fetchContent.title,
                shortDescription: fetchContent?.description ?? '',
                publishDate: fetchContent?.publishDate ?? '',
                _table_name: table,
                createdTime: new Date().getTime(),
              },
            ]
            updateLastView = updateLastView.slice(-5)
            localStorage.setItem('lastView_02', JSON.stringify(updateLastView))
          }
        }

        setLoading(false)
      })
      .catch(err => {
        console.error(err)
      })
  }, [ id])
  return (
    <div>
      {fileAttach.fileType === 'mp4' && (
        <ModalVideo
          channel="custom"
          isOpen={isOpenModalVideo}
          url={fileAttach.fileUrl ?? ''}
          onClose={() => setOpenModalVideo(false)}
        />
      )}
      <section className="update-breadcrumb">
        <div className="auto-container">
          <div className="sec-title">
            <div className="clearfix">
              <ul className="cbreadcrumb">
                <li>
                  <Link to="/">หน้าหลัก</Link>
                </li>
                <li>{renderMainMenuBC()}</li>
                {content && <li>{renderTitleBC(content.title)}</li>}
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="search-keyword-type2">
        <div className="auto-container">
          <div className="newsletter-form">
            <SearchInPageForm />

            <div className="row">
              <div className="content-side col-lg-8 col-md-12 col-sm-12" style={{ paddingTop: 35 }}>
                {loading && <SkeletonContentDetail />}
                {!loading && content && (
                  <div className="news-detail">
                    <div className="inner-box">
                      {content.youtubeLink && (
                        <iframe
                          style={{ width: '100%', height: 400 }}
                          src={`https://www.youtube.com/embed/${getYoutubeKey(
                            content.youtubeLink
                          )}`}
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen></iframe>
                      )}

                      {fileAttach.fileType !== 'mp4' && !content.youtubeLink && (
                        <div className="image">
                          <img src={`${process.env.REACT_APP_MEDIA_URL}/${content.path_pic}`} alt="" />
                        </div>
                      )}

                      {fileAttach.fileType === 'mp4' && !content.youtubeLink && (
                        <video style={{ width: '100%', height: 400 }} controls>
                          <source
                            src={`${process.env.REACT_APP_MEDIA_URL}/${content.videoUrlOrFileUrl}`}
                            type="video/mp4"
                          />
                        </video>
                      )}
                      {fileAttach.fileType === 'mp4' && !content.youtubeLink && (
                        <a
                          href={`${process.env.REACT_APP_MEDIA_URL}/${content.videoUrlOrFileUrl}`}
                          target={`_blank`}
                          download
                          className="theme-btn btn-style-show_all text-center">
                          <span className="txt">
                            <i className="fa fa-download" aria-hidden="true"></i> ดาวน์โหลด
                          </span>
                        </a>
                      )}

                      <div className="lower-content pt-0">
                        <div className="post-share-options" style={{ marginTop: 0 }}>
                          <div className="post-share-inner clearfix">
                            <div className="pull-left">
                              <ul className="post-meta">
                                <li>
                                  <span className="icon fa fa-calendar"></span>
                                  <span>{content.publishDate}</span>
                                </li>
                              </ul>
                            </div>
                            <div className="pull-right">
                            <div className="mt-2" style={{ textAlign: 'right' }}>
                            <FacebookShareButton
                              className="mx-1"
                              quote={content.title}
                              url={window.location.href}>
                              <FacebookIcon size={32} round={true} />
                            </FacebookShareButton>
                            <LineShareButton
                              className="mx-1"
                              title={content.title}
                              url={window.location.href}>
                              <LineIcon size={32} round={true} />
                            </LineShareButton>
                          </div>
                          </div>
                          </div>
                        </div>
                        <div className="mt-3 blog-detail">
                          <h5 className="mb-3 cb">{content.title}</h5>

                          <div dangerouslySetInnerHTML={{ __html: content.description }}></div>

                          {showWordingCheck && (<div className="text-center" style={{ color: '#000' }}>
                            <h5 className="mb-1">
                              หากท่านมีข้อสงสัยเกี่ยวกับความรู้ด้านผลิตภัณฑ์สุขภาพ
                              ว่าเป็นข้อเท็จจริงหรือไม่
                              <br />
                              สามารถส่งเรื่องให้ทาง อย. ช่วยเช็กให้ชัวร์ โดยการคลิกที่ปุ่มด้านล่าง
                            </h5>
                            <div>
                              <Link
                                to="/form-check-sure-share"
                                className="theme-btn btn-style-show_all text-center">
                                <span className="txt">เช็กให้ชัวร์กับ อย.</span>
                              </Link>
                            </div>
                          </div>)}

                          
                        </div>
                        <div className="clearfix">
                          {content?.galleries &&
                            Array.isArray(content.galleries) &&
                            content.galleries.map((gallery, index) => (
                              <div key={`gallery${index}`}>
                                <img src={`${process.env.REACT_APP_MEDIA_URL}/${gallery.filename}`} style={{ maxWidth: '100%' }} />
                              </div>
                            ))}
                        </div>
                        <div className="clearfix box_download_and_social">
                          <div className="pull-left">
                            {content.videoUrlOrFileUrl && fileAttach.fileType === 'pdf' && (
                              <p className="mt-3">
                                <b>เอกสารสำหรับดาวน์โหลด :</b>{' '}
                                <a
                                  target="_blank"
                                  href={`${process.env.REACT_APP_MEDIA_URL}/${content.videoUrlOrFileUrl}`}
                                  rel="noreferrer">
                                  <img
                                    className="icon-download"
                                    src="/assets/images/icons/ipdf-download.png"
                                    alt=""
                                  />
                                </a>
                              </p>
                            )}
                          </div>
                          <div className="pull-right">
                          <div className="mt-4" style={{ textAlign: 'right' }}>
                            <FacebookShareButton
                              className="mx-1"
                              quote={content.title}
                              url={window.location.href}>
                              <FacebookIcon size={32} round={true} />
                            </FacebookShareButton>
                            <LineShareButton
                              className="mx-1"
                              title={content.title}
                              url={window.location.href}>
                              <LineIcon size={32} round={true} />
                            </LineShareButton>
                          </div>
                          </div>
                        </div>

                        <div className="post-share-options">
                          <div className="post-share-inner clearfix">
                            <div className="pull-left">
                              <div className="post-title">Tags:</div>
                              <ul className="tags">
                                {content.tags &&
                                  content.tags.map((tag, index) => (
                                    <li
                                      style={{
                                        backgroundColor: '#f1f1f1',
                                        padding: '3px 15px',
                                        borderRadius: '3px',
                                        margin: '3px',
                                      }}
                                      key={`tag${index}`}>
                                      <Link to={`/search?keyword=${tag}&category=all`}>{tag}</Link>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="sidebar-side col-12 col-lg-4 col-md-4 mt-3 spa">
                <aside className="sidebar sticky-top ">
                  {/* <div className="fb-page" data-href="https://www.facebook.com/FDAThai" data-tabs="timeline" data-width="" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/FDAThai" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/FDAThai">FDA Thai</a></blockquote></div> */}
                  {process.env.NODE_ENV !== 'development' && (
                    <iframe
                      src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FFDAThai&tabs=timeline&width=340&height=331&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=367801984327528"
                      width="340"
                      height="331"
                      style={{ border: 'none', overflow: 'hidden' }}
                      scrolling="no"
                      frameBorder="0"
                      allowFullScreen={true}
                      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                  )}
                  <SuggestContent />
                  <TagSearch />
                </aside>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="auto-container">
          <hr></hr>
        </div>
      </section>
      {!loading && content?.relate && content?.relate.length > 0 && (
        <section className="news-section non_padding">
          <div className="auto-container">
            <div className="sec-title">
              <div className="clearfix">
                <div className="text-center">
                  <h5 className="color_green">บทความที่เกี่ยวข้อง</h5>
                </div>
              </div>
            </div>

            <div className="row clearfix">
              {content?.relate?.slice(0, 3).map((content, index) => (
                <BlogContentBox
                  key={`relateContent-${index}`}
                  content={content}
                  className="news-block style-two col-lg-4 col-md-4 col-sm-12 max-height-pic-195"
                />
              ))}
            </div>
          </div>
        </section>
      )}

      <section className="news-section non_padding">
        <div className="auto-container">
          <div className="sec-title">
            <div className="clearfix">
              <div className="text-center">
                <h5 className="color_green">ประวัติการเข้าชม</h5>
              </div>
            </div>
          </div>

          <div className="row clearfix">
            {content &&
              lastView
              ?.filter((e:any) => parseInt(e.id) !== content.id)
                .reverse()
                .slice(0, 3)
                .map((content: any, index: number) => (
                  <LastViewContentBox
                    key={`LastViewContentBox-${index}`}
                    content={content}
                    className="news-block style-two col-lg-4 col-md-4 col-sm-12 max-height-pic-195"
                  />
                ))}
          </div>
        </div>
      </section>
    </div>
  )
}

export default SuggestVideoDetail
