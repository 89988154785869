import axios from 'axios'

export const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT ?? 'https://api.oryor.com',
  headers: {
    'X-Authorization': `keeneye`,
  },
})

export const setupAdminApiAxiosInterceptor = (store: any) => {
  // const { dispatch } = store

  apiInstance.interceptors.request.use((config: any) => {
    // const token = store.getState()['adminUser']?.info?.access_token

    // if (token)
    // config.headers.common['Authorization'] = `Bearer ${AUTH_TOKEN_TEMP}`

    return config
  })

  apiInstance.interceptors.response.use(
    (response: any) => {
      return response
    },
    (error: { response: { status: number } }) => {
      if (error?.response?.status === 401) {
        // dispatch(clearState())
      }

      return Promise.reject(error)
    }
  )
}
