import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { mediaContent } from 'interface'
interface elementProps {
  className?: string
  theme?: string
  content: mediaContent
  page?: string
}
const YourPageContentBox = (props: elementProps) => {
  const regex = /(<([^>]+)>)/gi
  let page

  if (props.page) {
    page = props.page
  } else {
    const param = useParams()
    page = param.page
  }

  const getYoutubeThumbnail = (url: string) => {
    const youtubeKey = getYoutubeKey(url)
    if (youtubeKey && youtubeKey.length === 11) {
      return `https://img.youtube.com/vi/${youtubeKey}/0.jpg`
    }
    return ''
  }

  const getYoutubeKey = (url: string) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
    const match = url.match(regExp)
    return match && match[7] ? match[7] : ''
  }

  const className = props.className
    ? props.className
    : 'news-block style-two col-lg-4 col-md-6 col-sm-12'
  const linkToDetail = `/your-page/checkSureShare/${props.content._table_name}/${props.content.id}`
  const picCover = props.content.youtubeLink
    ? getYoutubeThumbnail(props.content.youtubeLink)
    : `${process.env.REACT_APP_MEDIA_URL}/${props.content.filePath}/${props.content.cover}`

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={className}>
      <div className="inner-box wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
        <div className="image">
          <img src={picCover} alt="" />

          <div className="overlay-box">
            <div className="overlay-inner">
              <div className="content">
                <Link to={linkToDetail} className="icon flaticon-unlink"></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="lower-content hl pt-2">
          <ul className="post-meta">
            <li>
              <Link to={linkToDetail}>
                <span className="icon fa fa-calendar"></span>
                {props.content.publishDate}
              </Link>
            </li>
          </ul>
          <Link to={linkToDetail}>
            <h5>{props.content.title.replace(regex, '')}</h5>
            <p
              dangerouslySetInnerHTML={{
                __html: props.content.shortDescription.replace(regex, ''),
              }}
              className="text-l-cb"
              style={{ color: '#000' }}></p>
          </Link>
          <Link to={linkToDetail} className="theme-btn btn-style-show_all text-center">
            <span className="txt">อ่านต่อ</span>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default YourPageContentBox
