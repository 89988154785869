import { atom } from 'recoil'

interface yourPageAtomContent {
  isOpen: boolean
  selected: string[]
}

export const yourPageAtom = atom<yourPageAtomContent>({
  key: 'yourPage',
  default: {
    isOpen: false,
    selected: [],
  },
})
