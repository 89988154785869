import React, { lazy } from 'react'
import { RouteObject, Navigate, useParams } from 'react-router-dom'
import { getPageFromTableName } from 'utils/'
const DefaultLayout = lazy(() => import('layouts/DefaultLayout'))
const HomeLayout = lazy(() => import('layouts/HomeLayout'))

const Home = lazy(() => import('views/Home/home'))
const CheckProductSerial = lazy(() => import('views/CheckProductSerial/checkProductSerial'))

const BlogSidebarPage = lazy(() => import('views/Media/blogSidebarPage'))
const ContentDetail = lazy(() => import('views/Media/contentDetail'))
const FormAppeal = lazy(() => import('views/FormAppeal/formAppeal'))
const FormCheckSureShare = lazy(() => import('views/FormCheckSureShare/formCheckSureShare'))
const FormContact = lazy(() => import('views/FormContact/formContact'))
const FormNotFoundSerial = lazy(() => import('views/FormNotFoundSerial/formNotFoundSerial'))

const SearchResult = lazy(() => import('views/SearchResult/searchResult'))

const YourpageSidebarPage = lazy(() => import('views/YourPage/yourpageSidebarPage'))
const YourPageContentDetail = lazy(() => import('views/YourPage/yourpageContentDetail'))

const PodcastContentDetail = lazy(() => import('views/Media/podcastContentDetail'))

const UnderConstruction = lazy(() => import('views/UnderConstruction/underConstruction'))


import AuthGuard from 'guard/AuthGuard'
import SuggestVideo from './views/SuggestVideo/suggestVideo'
import SuggestVideoDetail from './views/SuggestVideo/suggestVideoDetail'

// import HomeLayout from 'layouts/HomeLayout';
// import DefaultLayout from 'layouts/DefaultLayout';

// import Home from 'views/Home/home';
// import CheckProductSerial from 'views/CheckProductSerial/checkProductSerial';

const RedirectOryorCheckProduct = () => {
  const urlParams = new URLSearchParams(location.search);
  const serial = urlParams.get('number_src');
  const ref = urlParams.get('ref');
  if ((serial !== 'undefined' && serial !== null && serial !== '') && (ref !== 'undefined' && ref !== null && ref !== '')) {
    return <Navigate to={`/check-product-serial?serial=${serial}&link=${ref}`} replace />
  } 
  else if((serial !== 'undefined' && serial !== null && serial !== '') && (ref === 'undefined' || ref === null || ref === '')){
    return <Navigate to={`/check-product-serial?serial=${serial}`} replace />
  }
  else if((serial === 'undefined' || serial === null || serial === '') && (ref !== 'undefined' && ref !== null && ref !== '')){
    return <Navigate to={`/check-product-serial?link=mobileapp`} replace />
  }
  else {
    return <Navigate to={`/check-product-serial`} replace />
  }
}
const RedirectOryor = () => {
  const { table, id } = useParams()
  if (typeof table === 'undefined') {
    return <Navigate to="/" replace />
  } else {
    const page = getPageFromTableName(table)
    return <Navigate to={`/media/${page}/${table}/${id}`} replace />
  }
}

const routes: RouteObject[] = [
  {
    path: '/',
    element: <HomeLayout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
    ],
  },
  // {
  //   path: "check-product-serial",
  //   element: <DefaultLayout />,
  //   children: [
  //     {index: true,  element: <CheckProductSerial /> },
  //   ]
  // }
  { path: '/%E0%B8%AD%E0%B8%A2/index/check_product', element: <RedirectOryorCheckProduct /> },
  { path: '/%E0%B8%AD%E0%B8%A2/check_product', element: <RedirectOryorCheckProduct /> },
  { path: '/oryor2015/check_product_ocr.php', element: <RedirectOryorCheckProduct /> },
  { path: '/%E0%B8%AD%E0%B8%A2/detail/:table/:id', element: <RedirectOryor /> },
  {
    path: '/',
    element: <DefaultLayout />,
    children: [
      {
        path: '/check-product-serial',
        element: <CheckProductSerial />,
      },
      {
        path: '/search',
        element: <SearchResult />,
      },
      {
        path: '/your-page',
        element: (
          <AuthGuard>
            <YourpageSidebarPage />
          </AuthGuard>
        ),
      },
      {
        path: '/your-page/:page/:table/:id',
        element: <YourPageContentDetail />,
      },
      // Temporary redirect
      /*{
        path: '/media/illegalProduct',
        element: <UnderConstruction />,
      },
      {
        path: '/media/illegalProduct/:table/:id',
        element: <UnderConstruction />,
      },*/
      // End Temporary redirect
      {
        path: '/media/:page',
        element: <BlogSidebarPage />,
      },
      {
        path: '/media/:page/:table/:id',
        element: <ContentDetail />,
      },
      {
        path: '/podcast/:id',
        element: <PodcastContentDetail />,
      },
      {
        path: '/appeal',
        element: <FormAppeal />,
      },
      {
        path: '/form-check-sure-share',
        element: <FormCheckSureShare />,
      },
      {
        path: '/contact',
        element: <FormContact />,
      },
      {
        path: '/not-found-product-serial',
        element: <FormNotFoundSerial />,
      },
      {
        path: '/vdo-recommend',
        element: <SuggestVideo />,
      },
      {
        path: '/vdo-recommend/:table/:id',
        element: <SuggestVideoDetail />,
      },

      
      

      { path: '*', element: <Navigate to="/" replace /> },
    ],
  },
]

export default routes
