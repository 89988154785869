import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { mediaContent } from 'interface'
interface elementProps {
  className?: string
  theme?: string
  content: mediaContent
  page?: string
}
const SidebarSuggestContentBox = (props: elementProps) => {
  const regex = /(<([^>]+)>)/gi
  let page
  if (props.page) {
    page = props.page
  } else {
    const param = useParams()
    page = param.page
  }
  const getYoutubeThumbnail = (url: string) => {
    const youtubeKey = getYoutubeKey(url)
    if (youtubeKey && youtubeKey.length === 11) {
      return `https://img.youtube.com/vi/${youtubeKey}/0.jpg`
    }
    return ''
  }
  const getYoutubeKey = (url: string) => {
    console.log('url', url)
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
    const match = url.match(regExp)
    return match && match[7] ? match[7] : ''
  }

  const className = props.className ? props.className : 'news-block style-two'
  const linkToDetail = `/media/${page}/${props.content._table_name}/${props.content.id}`
  const picCover = props.content.youtubeLink
    ? getYoutubeThumbnail(props.content.youtubeLink)
    : `${process.env.REACT_APP_CONTENT_MEDIA_URL}/${props.content.filePath}/${props.content.cover}`
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={className}>
      <div className="inner-box wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
        <div className="image">
          <Link to={linkToDetail}>
            <img src={picCover} alt="" />
          </Link>

          <div className="overlay-box">
            <div className="overlay-inner">
              <div className="content">
                <Link to={linkToDetail} className="icon flaticon-unlink"></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="lower-content pt-2 pl-2">
          <Link to={linkToDetail}>
            <p>{props.content.title}</p>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default SidebarSuggestContentBox
